
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";

import { defineComponent, ref } from "vue";
import {
  ISubscribedTargetResponse,
  SubscribedTargetResponse,
} from "@evolutivelabs/quokka-common";
import { useSubscribedTargetStore } from "@/store/subscribedTarget.store";
export default defineComponent({
  components: { Dialog, InputText },
  setup() {
    const showDialog = ref<boolean>(false);
    const target = ref<ISubscribedTargetResponse>(
      new SubscribedTargetResponse()
    );
    const subscribedTargetStore = useSubscribedTargetStore();
    const newName = ref<string>("");

    async function open(t: ISubscribedTargetResponse) {
      target.value = { ...t };
      newName.value = target.value.displayName;
      showDialog.value = true;
    }

    async function renameSubscribe() {
      await subscribedTargetStore.rename(
        target.value.id,
        newName.value || target.value.title
      );
      showDialog.value = false;
    }

    return {
      target,
      newName,
      showDialog,
      open,
      renameSubscribe,
    };
  },
});
