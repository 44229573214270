
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Chip from "primevue/chip";

import { defineComponent, ref } from "vue";
import {
  IShareTargetResponse,
  TargetResponse,
} from "@evolutivelabs/quokka-common";
import { useSubscribedTargetStore } from "@/store/subscribedTarget.store";
export default defineComponent({
  components: { Dialog, InputText, Chip },
  setup() {
    const showDialog = ref<boolean>(false);
    const target = ref<TargetResponse>(new TargetResponse());
    const emailToShare = ref<string>("");
    const subscribedTargetStore = useSubscribedTargetStore();
    const errorMessage = ref<string>("");
    const subscribers = ref<IShareTargetResponse[]>([]);

    const emailRegexp =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    async function open(t: TargetResponse) {
      target.value = { ...t };
      showDialog.value = true;
      subscribers.value = await subscribedTargetStore.fetchSubscribers(t.id);
    }

    async function shareTarget() {
      if (!emailToShare.value.match(emailRegexp)) {
        errorMessage.value = `${emailToShare.value} is not email`;
        return;
      }

      if (subscribers.value.some((s) => s.email === emailToShare.value)) {
        errorMessage.value = `${emailToShare.value} already be shared`;
        return;
      }

      const result = await subscribedTargetStore.share(
        target.value.id,
        emailToShare.value
      );
      if (result === false) {
        errorMessage.value = `${emailToShare.value} is not quokka user`;
      } else {
        emailToShare.value = "";
        subscribers.value.push(result);
      }
    }
    function removeSubscriber(subscriberId: string) {
      subscribedTargetStore.removeSubscriber(target.value.id, subscriberId);
    }

    return {
      showDialog,
      open,
      emailToShare,
      shareTarget,
      errorMessage,
      subscribers,
      removeSubscriber,
    };
  },
});
