<template>
  <Dialog
    header="Target Information"
    :modal="true"
    class="w-7"
    :dismissableMask="true"
    v-model:visible="showDialog"
  >
    <div
      class="
        flex
        justify-content-between
        align-items-center
        py-2
        border-bottom-1 border-black-alpha-10
      "
    >
      <span>
        <label for="title" class="mr-1">Title:</label>
        <InputText
          v-model="target.title"
          id="title"
          :class="{ 'p-invalid': !validator.title }"
        />
      </span>
      <span>
        <span class="mr-1">Unit:</span>
        <Dropdown
          v-model="target.unit"
          :options="currencies"
          optionLabel="name"
          optionValue="value"
          placeholder="Select a Unit"
        />
      </span>
      <span
        >Value:
        <span class="text-2xl">{{ target.value }}</span>
      </span>
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Tag:</span>
      <Tag class="mr-1" rounded v-for="tag in target.tags" :key="tag.id">
        {{ tag.name }}
      </Tag>
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Source:</span>
      <a
        :href="target.url"
        target="_blank"
        rel="noreferrer noopener nofollow"
        class="text-overflow-ellipsis"
        >{{ target.url }}</a
      >
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Update Frequency:</span>
      <SelectButton
        v-model="target.updateFrequencyInMinute"
        :options="frequencies"
        optionLabel="name"
        optionValue="value"
      />
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Goal:</span>
      Value
      <Button
        :disabled="target.enableGoalTrigger ? undefined : 'disabled'"
        @click="changeGoalDirection"
        :label="target.goalTriggerDirection"
        class="p-button-outlined mx-2 w-2"
      />
      than
      <InputNumber
        :disabled="target.enableGoalTrigger ? undefined : 'disabled'"
        v-model="target.goalTriggerValue"
        class="mx-2"
        :maxFractionDigits="2"
      />
      <InputSwitch v-model="target.enableGoalTrigger" />
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Alert:</span>
      Value
      <Button
        :disabled="target.enableAlertTrigger ? undefined : 'disabled'"
        @click="changeAlertDirection"
        :label="target.alertTriggerDirection"
        class="p-button-outlined mx-2 w-2"
      />
      than
      <InputNumber
        :disabled="target.enableAlertTrigger ? undefined : 'disabled'"
        v-model="target.alertTriggerValue"
        class="mx-2"
        :maxFractionDigits="2"
      />
      <InputSwitch v-model="target.enableAlertTrigger" />
    </div>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="showDialog = false"
          class="p-button-text"
        />
        <Button label="Save" @click="confirm" icon="pi pi-check" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import Tag from "primevue/tag";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import { computed, defineComponent, ref } from "vue";
import { TargetResponse, TriggerDirection } from "@evolutivelabs/quokka-common";
import { useTargetStore } from "@/store/target.store";

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
    Tag,
    SelectButton,
    InputNumber,
    InputSwitch,
  },
  setup() {
    const showDialog = ref<boolean>(false);

    const target = ref<TargetResponse>(new TargetResponse());

    const validator = computed<Record<string, boolean>>(() => ({
      title: target.value.title.length > 0,
    }));

    function open(t: TargetResponse) {
      target.value = { ...t };
      showDialog.value = true;
    }

    const targetStore = useTargetStore();

    async function confirm() {
      const validations: boolean[] = Object.values(validator.value);
      if (!validations.every((x) => x)) {
        console.log("do nothing");
      } else {
        await targetStore.update(target.value.id, {
          title: target.value.title,
          unit: target.value.unit,
          updateFrequencyInMinute: target.value.updateFrequencyInMinute,
          goalTriggerDirection: target.value.goalTriggerDirection,
          goalTriggerValue: target.value.goalTriggerValue,
          enableGoalTrigger: target.value.enableGoalTrigger,
          alertTriggerDirection: target.value.alertTriggerDirection,
          alertTriggerValue: target.value.alertTriggerValue,
          enableAlertTrigger: target.value.enableAlertTrigger,
        });
        showDialog.value = false;
      }
    }

    const currencies = [
      { name: "-----", value: "" },
      { name: "NTD", value: "NT$" },
      { name: "USD", value: "$" },
      { name: "EUR", value: "€" },
      { name: "GBP", value: "£" },
    ];

    const frequencies = [
      { name: "Every 5 Mins", value: 5 },
      { name: "Every 1 Hour", value: 60 },
      { name: "Every 24 Hours", value: 1440 },
    ];

    const changeGoalDirection = () => {
      target.value.goalTriggerDirection =
        target.value.goalTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const alertDirection = computed(() => {
      if (target.value.alertTriggerDirection === TriggerDirection.greater)
        return ">";
      return "<";
    });

    const changeAlertDirection = () => {
      target.value.alertTriggerDirection =
        target.value.alertTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    return {
      confirm,
      target,
      open,
      showDialog,
      validator,
      currencies,
      frequencies,
      changeGoalDirection,
      alertDirection,
      changeAlertDirection,
    };
  },
});
</script>
