export function calculateTimeAgo(updateTimeStr: string): string {
  const updateTime = Date.parse(updateTimeStr);
  const timeSpan = Date.now() - updateTime;
  const days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
  if (days > 0) return `updated ${days} ${days > 1 ? "days" : "day"} ago`;
  const hours = Math.floor(timeSpan / (1000 * 60 * 60));
  if (hours > 0) return `updated ${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  const minutes = Math.floor(timeSpan / (1000 * 60));
  return `updated ${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
}
