import { defineStore } from "pinia";
import axios from "axios";
import {
  API_ROOT,
  ApiRoute,
  IShareTargetDto,
  IShareTargetResponse,
  ISubscribedTargetResponse,
  IUpdateSubscriptionDto,
  SubscribedTargetResponse,
  validateType,
} from "@evolutivelabs/quokka-common";
import { ref } from "vue";
import { IsEmail, IsUUID } from "class-validator";

const api = axios.create({
  baseURL: `/${API_ROOT}/${ApiRoute.Target}`,
  timeout: 3000,
});

class ShareTargetResponse implements IShareTargetResponse {
  @IsEmail()
  email!: string;
  @IsUUID()
  userId!: string;
}

export const useSubscribedTargetStore = defineStore("subscribedTarget", () => {
  const subscribedTargets = ref<ISubscribedTargetResponse[]>([]);

  async function fetch() {
    const resp = await api.get("subscriptions");
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(SubscribedTargetResponse, d))
    ) {
      subscribedTargets.value = resp.data;
    }
  }

  async function remove(targetId: string, userId: string) {
    await api.delete(`${targetId}/share/${userId}`);
    subscribedTargets.value = subscribedTargets.value.filter(
      (t) => t.id !== targetId
    );
  }

  async function rename(targetId: string, displayName: string) {
    const dto: IUpdateSubscriptionDto = { displayName };
    try {
      await api.patch(`${targetId}/share`, dto);
      const renameTarget = subscribedTargets.value.find(
        (x) => x.id === targetId
      );
      if (renameTarget) {
        renameTarget.displayName = displayName;
      }
    } catch {
      return false;
    }
  }

  async function share(
    targetId: string,
    email: string
  ): Promise<false | IShareTargetResponse> {
    const dto: IShareTargetDto = { email };
    try {
      const resp = await api.post(`${targetId}/share`, dto);
      if (validateType(ShareTargetResponse, resp.data)) {
        return resp.data;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  async function fetchSubscribers(
    targetId: string
  ): Promise<IShareTargetResponse[]> {
    const resp = await api.get(`${targetId}/share`);
    if (
      Array.isArray(resp.data) &&
      resp.data.every((d) => validateType(ShareTargetResponse, d))
    ) {
      return resp.data;
    } else {
      throw new Error("ShareTargetResponse type error");
    }
  }

  async function removeSubscriber(targetId: string, subscriberId: string) {
    await api.delete(`${targetId}/share/${subscriberId}`);
  }

  return {
    subscribedTargets,
    fetch,
    remove,
    share,
    rename,
    fetchSubscribers,
    removeSubscriber,
  };
});
