<template>
  <Dialog
    header="Combined Target Information"
    :modal="true"
    class="w-7"
    :dismissableMask="true"
    v-model:visible="showDialog"
  >
    <div
      class="
        flex
        justify-content-between
        align-items-center
        py-2
        border-bottom-1 border-black-alpha-10
      "
    >
      <span>
        <label for="title" class="mr-1">Title:</label>
        <InputText
          v-model="combinedTarget.title"
          id="title"
          placeholder="Set Title"
          :class="{ 'p-invalid': confirmed && !validator.title }"
        />
      </span>
      <span>
        <span class="mr-1">Unit:</span>
        <Dropdown
          v-model="combinedTarget.unit"
          :options="currencies"
          optionLabel="name"
          optionValue="value"
          placeholder="Select a Unit"
        />
      </span>
      <span v-if="dialogType === 'update'"
        >Value:
        <span class="text-2xl">{{ combinedTarget.value }}</span>
      </span>
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Source:</span>
      <div class="flex flex-column">
        <div
          class="flex align-items-center my-1"
          v-for="(item, index) in combinedTarget.sources"
          :key="item.id"
        >
          <Button
            v-if="combinedTarget.sources.length > 1"
            icon="pi pi-minus-circle"
            class="mx-1 p-button-rounded p-button-text"
            @click="combinedTarget.sources.splice(index, 1)"
          ></Button>
          <Dropdown
            class="w-5"
            :class="{ 'p-invalid': confirmed && item.id.length === 0 }"
            v-model="item.id"
            :options="targetList"
            optionLabel="name"
            optionValue="value"
            optionDisabled="disabled"
            placeholder="Select a Target"
          />
          <i class="mx-1 pi pi-times" />
          <InputNumber
            v-model="item.multiplier"
            :maxFractionDigits="3"
            placeholder="multiplier"
          />
          <Button
            v-if="combinedTarget.sources.length < targetList.length"
            icon="pi pi-plus-circle"
            class="ml-1 p-button-rounded p-button-text"
            @click="pushCandidateToSources"
            :disabled="item.id.length === 0"
          ></Button>
        </div>
      </div>
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Goal:</span>
      Value
      <Button
        :disabled="combinedTarget.enableGoalTrigger ? undefined : 'disabled'"
        @click="changeGoalDirection"
        :label="combinedTarget.goalTriggerDirection"
        class="p-button-outlined mx-2 w-2"
      />
      than
      <InputNumber
        :disabled="combinedTarget.enableGoalTrigger ? undefined : 'disabled'"
        v-model="combinedTarget.goalTriggerValue"
        class="mx-2"
        :maxFractionDigits="2"
      />
      <InputSwitch v-model="combinedTarget.enableGoalTrigger" />
    </div>

    <div
      class="flex align-items-center py-2 border-bottom-1 border-black-alpha-10"
    >
      <span class="mr-2">Alert:</span>
      Value
      <Button
        :disabled="combinedTarget.enableAlertTrigger ? undefined : 'disabled'"
        @click="changeAlertDirection"
        :label="combinedTarget.alertTriggerDirection"
        class="p-button-outlined mx-2 w-2"
      />
      than
      <InputNumber
        :disabled="combinedTarget.enableAlertTrigger ? undefined : 'disabled'"
        v-model="combinedTarget.alertTriggerValue"
        class="mx-2"
        :maxFractionDigits="2"
      />
      <InputSwitch v-model="combinedTarget.enableAlertTrigger" />
    </div>

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="showDialog = false"
          class="p-button-text"
        />
        <Button :label="confirmLabel" @click="confirm" icon="pi pi-check" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import { computed, defineComponent, ref } from "vue";
import {
  CombinedTargetResponse,
  TriggerDirection,
  validateType,
} from "@evolutivelabs/quokka-common";
import { useCombinedTargetStore } from "@/store/combinedTarget.store";
import { useTargetStore } from "@/store/target.store";

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
    InputNumber,
    InputSwitch,
  },
  setup() {
    const showDialog = ref<boolean>(false);

    const dialogType = ref<"create" | "update">("update");

    const combinedTarget = ref<CombinedTargetResponse>(
      new CombinedTargetResponse()
    );

    const validator = computed<Record<string, boolean>>(() => ({
      title: combinedTarget.value.title.length > 0,
      sources: combinedTarget.value.sources.every((s) => s.id.length > 0),
    }));

    function open(t?: CombinedTargetResponse) {
      confirmed.value = false;
      if (t !== undefined) {
        dialogType.value = "update";
        const deepCopy = JSON.parse(JSON.stringify(t));
        if (validateType(CombinedTargetResponse, deepCopy)) {
          combinedTarget.value = deepCopy;
        }
      } else {
        dialogType.value = "create";
        combinedTarget.value = new CombinedTargetResponse();
        pushCandidateToSources();
      }
      showDialog.value = true;
    }

    function pushCandidateToSources() {
      combinedTarget.value.sources.push({ id: "", multiplier: 1 });
    }

    const combinedTargetStore = useCombinedTargetStore();

    const confirmed = ref(false);

    async function confirm() {
      confirmed.value = true;
      const validations: boolean[] = Object.values(validator.value);
      const dto = {
        title: combinedTarget.value.title,
        unit: combinedTarget.value.unit,
        goalTriggerDirection: combinedTarget.value.goalTriggerDirection,
        goalTriggerValue: combinedTarget.value.goalTriggerValue,
        enableGoalTrigger: combinedTarget.value.enableGoalTrigger,
        alertTriggerDirection: combinedTarget.value.alertTriggerDirection,
        alertTriggerValue: combinedTarget.value.alertTriggerValue,
        enableAlertTrigger: combinedTarget.value.enableAlertTrigger,
        sources: combinedTarget.value.sources,
      };
      if (!validations.every((x) => x)) {
        console.log("do nothing");
      } else if (dialogType.value === "update") {
        await combinedTargetStore.update(combinedTarget.value.id, dto);
        showDialog.value = false;
      } else if (dialogType.value === "create") {
        await combinedTargetStore.create(dto);
        showDialog.value = false;
      }
    }

    const confirmLabel = computed(() => {
      if (dialogType.value === "update") return "Save";
      return "Create";
    });

    const currencies = [
      { name: "-----", value: "" },
      { name: "NTD", value: "NT$" },
      { name: "USD", value: "$" },
      { name: "EUR", value: "€" },
      { name: "GBP", value: "£" },
    ];

    const goalDirection = computed(() => {
      if (
        combinedTarget.value.goalTriggerDirection === TriggerDirection.greater
      )
        return ">";
      return "<";
    });

    const changeGoalDirection = () => {
      combinedTarget.value.goalTriggerDirection =
        combinedTarget.value.goalTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const alertDirection = computed(() => {
      if (
        combinedTarget.value.alertTriggerDirection === TriggerDirection.greater
      )
        return ">";
      return "<";
    });

    const changeAlertDirection = () => {
      combinedTarget.value.alertTriggerDirection =
        combinedTarget.value.alertTriggerDirection === TriggerDirection.greater
          ? TriggerDirection.less
          : TriggerDirection.greater;
    };

    const targetList = computed(() => {
      return useTargetStore().targets.map((e) => {
        let disabled = false;
        combinedTarget.value.sources.forEach((s) => {
          if (e.id === s.id) disabled = true;
        });
        return { name: e.title, value: e.id, disabled };
      });
    });

    return {
      confirm,
      confirmed,
      confirmLabel,
      combinedTarget,
      open,
      showDialog,
      validator,
      currencies,
      goalDirection,
      changeGoalDirection,
      alertDirection,
      changeAlertDirection,
      targetList,
      dialogType,
      pushCandidateToSources,
    };
  },
});
</script>
